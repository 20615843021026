import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSearchParam } from "react-use";
import { v4 } from "uuid";
import { Box, Button, Flex, Loading, styled } from "@100mslive/react-ui";
import { Header } from "./Header";
import { ErrorDialog } from "../primitives/DialogContent";
import { useSetUiSettings, useTokenEndpoint } from "./AppData/useUISettings";
import PreviewContainer from "./Preview/PreviewContainer";
import SidePane from "../layouts/SidePane";
import { useNavigation } from "./hooks/useNavigation";


import getToken from "../services/tokenService";

import {
  QUERY_PARAM_SKIP_PREVIEW_HEADFUL,
  QUERY_PARAM_NAME,
  QUERY_PARAM_SKIP_PREVIEW,
  QUERY_PARAM_AUTH_TOKEN,
  UI_SETTINGS,
} from "../common/constants";
import { getDebugName } from "mobx";
import store from "../store";

/**
 * query params exposed -
 * skip_preview=true => used by recording and streaming service, skips preview and directly joins
 *                      header and footer don't show up in this case
 * skip_preview_headful=true => used by automation testing to skip preview without impacting the UI
 * name=abc => gives the initial name for the peer joining
 * auth_token=123 => uses the passed in token to join instead of fetching from token endpoint
 * ui_mode=activespeaker => lands in active speaker mode after joining the room
 */

const env = process.env.REACT_APP_ENV;
const PreviewScreen = React.memo(({ getUserToken }) => {
  const navigate = useNavigation();
  const tokenEndpoint = useTokenEndpoint();
  const [, setIsHeadless] = useSetUiSettings(UI_SETTINGS.isHeadless);
  const { session_id: sessionID, role: userRole } = useParams(); // from the url
  const [hmsInit, setHmsInit] = useState(null);
  const [error, setError] = useState({ title: "", body: "" });
  // way to skip preview for automated tests, beam recording and streaming
  const beamInToken = useSearchParam("token") === "beam_recording"; // old format to remove
  let skipPreview = useSearchParam(QUERY_PARAM_SKIP_PREVIEW) === "true";
  // use this field to join directly for quick testing while in local
  const directJoinHeadfulFromEnv =
    process.env.REACT_APP_HEADLESS_JOIN === "true";
  const directJoinHeadful =
    useSearchParam(QUERY_PARAM_SKIP_PREVIEW_HEADFUL) === "true" ||
    directJoinHeadfulFromEnv;
  skipPreview = skipPreview || beamInToken || directJoinHeadful;
  const initialName =
    useSearchParam(QUERY_PARAM_NAME) || (skipPreview ? "Beam" : "");
  let authToken = useSearchParam(QUERY_PARAM_AUTH_TOKEN);

  useEffect(() => {
    if (hmsInit) {
      // setHmsInit(hmsInit);
      return;
    }
    if (!sessionID || !userRole) {
      return;
    }

    if (userRole != "therapist" && userRole != "client") {
      setError({
        title: "Video room init error",
        body: "Invalid user"
      })
      return;
    }

    hmsVideoInit(userRole == "therapist", sessionID)
      .then(response => {
        console.log(response)
        if (response.error) {
          setError({
            title: "Video room init error",
            body: response.error_message
          })
        } else {
          store.setState("hms_init_response", response);
          setHmsInit(response)
        }
      }).catch(error => {
        setError(convertError(error));

      })
  }, [userRole, sessionID]);

  const fetchWithRetry = async (url, options) => {
    const MAX_RETRIES = 4;
    let error = Error("something went wrong");
    for (let i = 0; i < MAX_RETRIES; i++) {
      try {
        return await fetch(url, options);
      } catch (err) {
        error = err;
      }
    }
    console.error("Fetch failed after max retries", { url, options });
    throw error;
  };

  async function hmsVideoInit(isTherapist, sessionBookingID) {
    const response = await fetchWithRetry(
      "https://api.cactusmamatelemental.co.ke/hms_video_init.php",
      {
        method: "post",
        body: JSON.stringify({
          session_booking_id: Number(sessionBookingID),
          is_therapist: isTherapist,
        }),
      }
    );

    if (!response.ok) {
      let error = new Error("Request failed!");
      error.response = response;
      throw error;
    }

    var data = await response.json();
    console.log(data)
    return data;
  }

  const joinRoom = () => {
    navigate("meeting/" + hmsInit.room_id + "/" + userRole)
  }

  const onJoin = () => {
    !directJoinHeadful && setIsHeadless(skipPreview);
    let meetingURL = `/meeting/${hmsInit.room_id}`;
    if (userRole) {
      meetingURL += `/${userRole}`;
    }
    if(sessionID){
      meetingURL += `/${sessionID}`;      
    }
    navigate(meetingURL);
  };

  const getName = () => {
    if (userRole == "therapist") {
      return hmsInit.therapist_name;
    } else {
      return hmsInit.patient_name;
    }
  }

  if (error.title) {
    return <ErrorDialog title={error.title}>{error.body}</ErrorDialog>;
  }
  return (
    <Flex direction="column" css={{ size: "100%" }}>
      <Box
        css={{ h: "$18", "@md": { h: "$17", flexShrink: 0 } }}
        data-testid="header"
      >
        <Header isPreview={true} />
      </Box>
      <Flex
        css={{ flex: "1 1 0", position: "relative", overflowY: "auto" }}
        justify="center"
        align="center"
      >
        {hmsInit ? (
          <>
            <PreviewContainer
              initialName={getName()}
              skipPreview={true}
              env={env}
              onJoin={onJoin}
              token={hmsInit.token}
            />
          </>
        ) : (
          <Loading size={100} />
        )}
        <SidePane
          css={{
            position: "unset",
            mr: "$10",
            "@lg": { position: "fixed", mr: "$0" },
          }}
        />
      </Flex>
    </Flex>
  );
});

const convertError = error => {
  console.error("[error]", { error });

  console.error("room initializing error", error);
  return {
    title: "Error initializing room",
    body: ErrorWithSupportLink(
      "Error occured while initializing room, try again later"
    ),
  };

};

const Link = styled("a", {
  color: "#2f80e1",
});

export const ErrorWithSupportLink = errorMessage => (
  <div>
    {errorMessage} If you think this is a mistake on our side, please create{" "}
    <Link
      target="_blank"
      href="https://github.com/100mslive/100ms-web/issues"
      rel="noreferrer"
    >
      an issue
    </Link>{" "}
    or reach out over{" "}
    <Link
      target="_blank"
      href="https://discord.com/invite/kGdmszyzq2"
      rel="noreferrer"
    >
      Discord
    </Link>
    .
  </div>
);

export default PreviewScreen;
